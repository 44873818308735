import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@ocuon/lib/firebase/firebase';
import {
  convertCollectionsSnapshotToMap,
} from '@ocuon/lib/firebase/firebase.util';


/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = 'public'; // change your collection
const ORDER_BY = 'id';
const ORDER = 'desc';

function* loadFromFirestore({ payload }) {
  const { id, singleModel } = payload

  // load a single model if the flag is set
  // need to refactor and maybe move out to models?
  if (singleModel) {
    try {
      const snapshot = yield call(rsf.firestore.getDocument, `models/${id}`);
      let data = snapshot.data()
      // will throw the 404 here if model is not private
      data.status === 'Published' ? 
        yield put(actions.loadFromFireStoreSuccess([data])) :
        yield put(actions.loadFromFireStoreError());
    } catch (error) {
      yield put(actions.loadFromFireStoreError(error));
    }
  }
  else {
    try {
      // get user details for this public page
      const snapshot = yield call(rsf.firestore.getDocument, `${COLLECTION_NAME}/${id}`);
      const publicPage = snapshot.data()
      // get all public models for this user
      // lots to manage here, 404 page etc
      const userId = publicPage.uid
      const collectionRef = db
        .collection('models')
        .where('owner', '==', userId)
        .where('deleted_at', '==', null)
        .where('status', '==', 'Published')
        .orderBy(ORDER_BY, ORDER);

      const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
      let data = yield call(convertCollectionsSnapshotToMap, snapshots);
      yield put(actions.loadFromFireStoreSuccess(data));
    } catch (error) {
      console.log(error);
      yield put(actions.loadFromFireStoreError(error));
    }
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  const { id, publicPage } = data
  const pageData = {
    uid: id
  }
  try {
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.key}`,
          {
            deleted_at: new Date().getTime(),
          }
        );
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.key}`,
          {
            ...omit(data, ['key']),
          }
        );
        break;
      default:
        yield call(rsf.firestore.setDocument, `${COLLECTION_NAME}/${publicPage}`, pageData);
        break;
    }
    //yield put({ type: actions.LOAD_FROM_FIRESTORE, payload: { data: data.owner } });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
  ]);
}
