import { combineReducers } from 'redux';
import App from '@ocuon/redux/app/reducer';
import Auth from '@ocuon/redux/auth/reducer';
import ThemeSwitcher from '@ocuon/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@ocuon/redux/languageSwitcher/reducer';
import modal from '@ocuon/redux/modal/reducer';
import User from '@ocuon/redux/users/reducers';
import Models from '@ocuon/redux/models/reducers';
import Public from '@ocuon/redux/publicPage/reducers';
import Requests from '@ocuon/redux/requests/reducers';
import Stripe from '@ocuon/redux/stripe/reducers';

const ReduxStore = require('@uppy/store-redux')

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  modal,
  User,
  uppy: ReduxStore.reducer,
  Models,
  Public,
  Requests,
  Stripe
});
