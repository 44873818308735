import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { db, rsf } from '@ocuon/lib/firebase/firebase';
import {
  convertCollectionsSnapshotToMap,
} from '@ocuon/lib/firebase/firebase.util';
import { getSubscriptionLimits } from '@ocuon/lib/helpers/getSubscriptionLimits';

/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = 'users';

// we only allow a single subscription at a time
function* loadFromFirestore({ payload }) {
  try {
    const snapshot = yield call(rsf.firestore.getDocument, `${COLLECTION_NAME}/${payload.data}`);
    const user = snapshot.data()

    const subscriptionRef = db
      .collection(`${COLLECTION_NAME}/${payload.data}/subscriptions`)
    const subsnapshot = yield call(rsf.firestore.getCollection, subscriptionRef);
    let subData = yield call(convertCollectionsSnapshotToMap, subsnapshot);

    if (Object.keys(subData).length) {
      const data = subData[Object.keys(subData)[0]]
      user.subscription = data
      user.limits = getSubscriptionLimits(data.role)
    } else {
      // get default limits
      user.limits = getSubscriptionLimits()
    }

    yield put(actions.loadFromFireStoreSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;

  try {
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.id}`,
          {
            deleted_at: new Date().getTime(),
          }
        );
        break;
      case 'update':
        yield call(
          rsf.firestore.updateDocument,
          `${COLLECTION_NAME}/${data.id}`,
          {
            ...omit(data, ['id'])
          }
        );
        break;
      case 'create':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.id}`,
          { ...data }
        );
        break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, data);
        break;
    }
    const uid = {
      data: payload.data.key || payload.data.id
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload: uid });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore)
  ]);
}
