import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
import 'firebase/functions';
import ReduxSagaFirebase from 'redux-saga-firebase';
import ocuonConfig from '@ocuon/config/firebase.config';

export const firebaseApp = !firebase.apps.length
  ? firebase.initializeApp(ocuonConfig)
  : firebase.app();

const cookieEnabled = ("cookie" in document && (document.cookie.length > 0 ||
  (document.cookie = "test").indexOf.call(document.cookie, "test") > -1));

export const auth = firebase.auth();
export const db = firebase.firestore();
export const analytics = cookieEnabled ? firebase.analytics() : null

export const storage = firebase.storage();
export const functions = firebaseApp.functions()
export const rsf = new ReduxSagaFirebase(firebaseApp);
export default firebase;
