import actions from './actions';

const initState = {
    id: null,
    displayName: null,
    photoURL: null,
    created_at: new Date().getTime(),
    isNewUser: false,
    stripeId: null,
    stripeLink: null,
    subscription: null,
    marketing: false
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...payload.data, 
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        article: payload.data == null ? initState.article : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        publicPage: payload.data.publicPage,
      };
    case actions.SAVE_INTO_FIRESTORE:
      return {
        ...state,
        displayName: payload.data.displayName,
        id: payload.data.key || payload.data.id,
        photoURL: payload.data.photoURL,
        ...(payload.data.isNewUser && { isNewUser: payload.data.isNewUser})
      };
    case actions.RESET:
      return {
        ...initState
      }
    default:
      return state;
  }
}
