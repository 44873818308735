import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  models: {},
  modalActive: false,
  previewModalActive: false,
  name: null
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        model: payload.data,
      };
    default:
      return state;
  }
}
