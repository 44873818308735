import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  models: {},
  modalActive: false,
  parentModalActive: false,
  previewModalActive: false,
  shareModalActive: false,
  viewType: null,
  usePoster: true,
  model: {
    key: null,
    title: '',
    owner: null,
    status: 'Draft',
    description: '',
    files: {
      src: '',
      iosSrc: '',
    },
    thumbnail: '',
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        models: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        model: payload.data == null ? initState.model : payload.data,
      };
    case actions.TOGGLE_PREVIEW_MODAL:
      return {
        ...state,
        previewModalActive: !state.previewModalActive,
        model: payload.data == null ? initState.model : payload.data,
      };
    case actions.TOGGLE_SHARE_MODAL:
      return {
        ...state,
        shareModalActive: !state.shareModalActive,
        model: payload.data == null ? initState.model : payload.data,
      };
    case actions.TOGGLE_PARENT_MODAL:
      return {
        ...state,
        viewType: payload.data.viewType,
        parentModalActive: payload.data.isActive,
        model: payload.data.model == null ? initState.model : payload.data.model,
      }
    case actions.UPDATE:
      return {
        ...state,
        model: payload.data,
      };
    default:
      return state;
  }
}
