import { all } from 'redux-saga/effects';
import authSagas from '@ocuon/redux/auth/saga';
import userSaga from '@ocuon/redux/users/sagas';
import modelsSaga from '@ocuon/redux/models/sagas';
import publicSaga from '@ocuon/redux/publicPage/sagas';
import requestSaga from '@ocuon/redux/requests/sagas';
import stripeSaga from '@ocuon/redux/stripe/sagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSaga(),
    modelsSaga(),
    publicSaga(),
    requestSaga(),
    stripeSaga()
  ]);
}
