import { all, takeEvery, put, fork } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@ocuon/lib/helpers/utility';
import actions from './actions';
import userActions from '../users/actions';
import { getCurrentUser } from '@ocuon/lib/firebase/firebase.authentication.util'
import authHelper from '@ocuon/lib/helpers/authHelper'

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    yield put({
      type: userActions.RESET
    })
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');

    if (token) {
      const isExpired = authHelper.checkExpirity(token)
      if (!isExpired.error) {
        // may need to refactor for fewer firebase calls on refresh?
        // store user info in local storage ?
        const user = yield getCurrentUser()
        if (user) {
          yield put({
            type: userActions.LOAD_FROM_FIRESTORE,
            payload: { data: user.uid }
          })
        }

        yield put({
          type: actions.LOGIN_SUCCESS,
          token
        });
      }
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
