import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { db, rsf } from '@ocuon/lib/firebase/firebase';
import {
  convertCollectionsSnapshotToMap,
} from '@ocuon/lib/firebase/firebase.util';
import { getCurrentUser } from '@ocuon/lib/firebase/firebase.authentication.util'

/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = 'products';

function* loadFromFirestore() {
  try {
    const collectionRef = db
      .collection(COLLECTION_NAME)
      .where('active', '==', true)
      .where('metadata.type', '==', 'subscription')

    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);

    for (const product in data) {
      const priceRef = db
        .collection(`${COLLECTION_NAME}/${product}/prices`)
      const snapshots = yield call(rsf.firestore.getCollection, priceRef);
      let priceData = yield call(convertCollectionsSnapshotToMap, snapshots);
      data[product].prices = priceData
    }
    yield put(actions.loadFromFireStoreSuccess(data))
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}


function* startSubscription({ payload }) {
  const { uid: userId } = yield getCurrentUser()

  // only do this if there's no other active subscription
  try {
    db
      .collection('users')
      .doc(userId)
      .collection('checkout_sessions')
      .add({
        price: payload.price,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      }).then(function (querySnapshot) {
        querySnapshot.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and 
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      });


  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.CREATE_SUBSCRIPTION, startSubscription)
  ]);
}
