const DOCUMENT = 'PRODUCTS_';

const actions = {
  LOAD_FROM_FIRESTORE: DOCUMENT + 'LOAD_FROM_FIRESTORE',
  LOAD_FROM_FIRESTORE_SUCCESS: DOCUMENT + 'LOAD_FROM_FIRESTORE_SUCCESS',
  LOAD_FROM_FIRESTORE_ERROR: DOCUMENT + 'LOAD_FROM_FIRESTORE_ERROR',

  CREATE_SUBSCRIPTION: DOCUMENT + 'CREATE_SUBSCRIPTION',
  CREATE_SUBSCRIPTION_SUCCESS: DOCUMENT + 'CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_ERROR: DOCUMENT + 'CREATE_SUBSCRIPTION_ERROR',


  loadFromFireStore: () => {
    return {
      type: actions.LOAD_FROM_FIRESTORE
    };
  },

  loadFromFireStoreSuccess: data => ({
    type: actions.LOAD_FROM_FIRESTORE_SUCCESS,
    payload: { data },
  }),

  loadFromFireStoreError: error => ({
    type: actions.LOAD_FROM_FIRESTORE_ERROR,
    payload: { error },
  }),

  createSubscription: (price) => ({
    type: actions.CREATE_SUBSCRIPTION,
    payload: { price },
  }),
/*
  createSubscriptionSuccess: data => ({
    type: actions.CREATE_SUBSCRIPTION_SUCCESS,
    payload: { data },
  }),

  createSubscriptionError: error => ({
    type: actions.CREATE_SUBSCRIPTION_ERROR,
    payload: { error },
  }),*/

};
export default actions;
