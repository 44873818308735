export const getSubscriptionLimits = (role) => {
    switch (role) {
        case 'starter':
            return {
                maxModelCount: 10,
                viewsLimit: 1000
            }
        case 'plus':
            return {
                maxModelCount: 20,
                viewsLimit: 20000
            }
        case 'advanced':
            return {
                maxModelCount: 50,
                viewsLimit: 50000
            }
        default:
            return {
                maxModelCount: 10,
                viewsLimit: 100
            }
    }
}