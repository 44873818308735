import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  requests: {},
  request: {
    key: null,
    id: new Date().getTime(),
    title: '',
    description: '',
    dimensions: '',
    productLink: '',
    files: [],
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        requests: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.SET_CURRENT_REQUEST:
      return {
        ...state,
        request: payload.data.request == null ?
          {
            key: null,
            id: new Date().getTime(),
            title: '',
            description: '',
            dimensions: '',
            productLink: '',
            files: [],
            created_at: new Date().getTime(),
            deleted_at: null, // soft delete
          }
          : payload.data.request,
      };
    case actions.UPDATE:
      return {
        ...state,
        request: payload.data,
      };
    default:
      return state;
  }
}
