import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { rsf, db } from '@ocuon/lib/firebase/firebase';
import {
  convertCollectionsSnapshotToMap,
} from '@ocuon/lib/firebase/firebase.util';
import { getCurrentUser } from '@ocuon/lib/firebase/firebase.authentication.util'


/**
 * DOC: https://redux-saga-firebase.js.org/reference/dev/firestore
 */

const COLLECTION_NAME = 'requests';
const ORDER_BY = 'id';
const ORDER = 'desc';

function* loadFromFirestore() {
  const { uid: userId } = yield getCurrentUser()

  try {
    const collectionRef = db
      .collection(COLLECTION_NAME)
      .where('owner', '==', userId)
      .where('deleted_at', '==', null)
      .orderBy(ORDER_BY, ORDER);

    const snapshots = yield call(rsf.firestore.getCollection, collectionRef);
    let data = yield call(convertCollectionsSnapshotToMap, snapshots);
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  const user = yield getCurrentUser()
  const { email, displayName } = user
  try {
    switch (actionName) {
      case 'delete':
        yield call(
          rsf.firestore.deleteDocument,
          `${COLLECTION_NAME}/${data.key}`
        );
        break;
      case 'update':
        yield call(
          rsf.firestore.setDocument,
          `${COLLECTION_NAME}/${data.key}`,
          {
            ...omit(data, ['key']),
          }
        );
        break;
      default:
        yield call(rsf.firestore.addDocument, COLLECTION_NAME, { ...data, email, displayName });
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE, payload: { data: data.owner } });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }

  // clear the request object after each 
  yield put({
    type: actions.SET_CURRENT_REQUEST, payload: {
      data: { request: null }
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
  ]);
}
